<template>
  <div>
    <VTitle :title="$t('app.logs')" class="section-title" />

    <LogData
      log-type="Profile"
      :rows="rows"
      :pagination="pagination"
      :is-loading="isLoading"
      hide-actions
      @update:current_page="onUpdateCurrentPage"
    />
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import usePaginate from "@/composables/usePaginate";
import useReadMultiple from "@/composables/useReadMultiple";
// Components
import LogData from "@/views/administration/logs/LogData";
import VTitle from "@/components/VTitle";

export default {
  components: {
    LogData,
    VTitle
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { pagination, data, isLoading, read } = useReadMultiple();

    // Computed
    const documentTitle = computed(() => {
      const title = `${t("app.logs", 2)} - ${t("app.profile")}`;

      const { firstname, lastname } = props.user ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });

    // METHODS
    const getData = async () => {
      await read({
        endpoint: "my.logs"
      });
    };

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getData();
    });

    // CUSTOM COMPOSABLES
    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    return {
      documentTitle,
      rows: data,
      pagination,
      onUpdateCurrentPage,
      isLoading
    };
  }
};
</script>
